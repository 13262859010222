<template>
	<q-item
		:tag="condition_field && ['ATTACHMENT', 'CHECKBOX'].includes(condition_field.field_type) ? 'label' : ''"
		class="full-width flex items-center q-py-none"
	>
		<div class="text-left full-width row" v-if="condition_field && condition_field.field_type == 'CHECKBOX'">
			<div style="width:50%;" class="flex items-center justify-start">{{field_group_name}} / {{condition_field.label}}</div>
			<div style="width:50%;" class="flex items-center justify-end">
				<q-toggle v-model="value_selection" :disable="is_inherited || is_disabled"/>
			</div>
		</div>
		<div class="text-left full-width row" v-if="condition_field && condition_field.field_type == 'ATTACHMENT'">
			<div style="width:50%;" class="flex items-center justify-start">{{field_group_name}} / {{condition_field.label}} has attachment?</div>
			<div style="width:50%;" class="flex items-center justify-end">
				<q-toggle v-model="value_selection" :disable="is_inherited || is_disabled"/>
			</div>
		</div>
		<q-input
			v-if="condition_field && (
					condition_field.field_type == 'TEXT' ||
					condition_field.field_type == 'EMAIL'
				)
			"
			v-model="value_selection"
			:label="field_group_name + ' / ' + condition_field.label"
			:disable="is_inherited || is_disabled"
			:error="error_messages['has_error_' + condition_field.label]"
			:error-message="error_messages['error_message_' + condition_field.label]"
			class="full-width"
		/>
		<!-- NUMBER -->
		<q-input
			v-if="condition_field && condition_field.field_type == 'NUMERIC'"
			class="full-width"
			type="number"
			:label="field_group_name + ' / ' + condition_field.label"
			v-model="value_selection"
			:disable="is_inherited || is_disabled"
			:error="error_messages['has_error_' + condition_field.label]"
			:error-message="error_messages['error_message_' + condition_field.label]"
			onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
		/>
		<!-- DATETIME -->
		<q-input v-if="condition_field && condition_field.field_type == 'DATETIME'"
			class="full-width"
			v-model="value_selection"
			:label="field_group_name + ' / ' + condition_field.label"
			:disable="is_inherited || is_disabled"
			:error="error_messages['has_error_' + condition_field.label]"
			:error-message="error_messages['error_message_' + condition_field.label]"
			hint="Format DD/MM/YYYY HH:MM:SS"
			@click="date_time_dialog = true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="calendar_today" class="cursor-pointer date-btn" @click="date_time_dialog=true"/>
			</template>
		</q-input>
		<q-dialog v-model="date_time_dialog">
			<div
				class="q-pa-md date-time-container"
				:class="$q.screen.lt.sm ? 'q-px-none q-py-md' : 'q-pa-md'"
				:style="$q.screen.lt.sm ? 'max-width: 88%;' : 'max-width: 1000px;'"
			>
				<div class="q-gutter-md row items-start date-time">
					<q-date
						v-model="value_selection"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
					<q-time
						format24h
						v-model="value_selection"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
				</div>
				<div class="q-mt-md flex justify-end full-width">
					<q-btn color="primary" flat label="Close" @click="date_time_dialog = false" />
				</div>
			</div>
		</q-dialog>
		<!-- DATE -->
		<q-input v-if="condition_field && condition_field.field_type == 'DATE'"
			v-model="value_selection"
			class="full-width"
			:label="field_group_name + ' / ' + condition_field.label"
			:disable="is_inherited || is_disabled"
			:error="error_messages['has_error_' + condition_field.label]"
			:error-message="error_messages['error_message_' + condition_field.label]"
			hint="Format DD/MM/YYYY"
			@click="show_date_modal = true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="event" class="cursor-pointer date-btn" @click="show_date_modal=true"/>
			</template>
		</q-input>
		<q-dialog v-model="show_date_modal">
			<q-date
				today-btn
				v-model="value_selection"
				mask="DD/MM/YYYY"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-date>
		</q-dialog>
		<!-- TIME -->
		<q-input v-if="condition_field && condition_field.field_type == 'TIME'"
			v-model="value_selection"
			class="full-width"
			:label="field_group_name + ' / ' + condition_field.label"
			:disable="is_inherited || is_disabled"
			:error="error_messages['has_error_' + condition_field.label]"
			:error-message="error_messages['error_message_' + condition_field.label]"
			hint="Format HH:MM:SS"
			@click="show_time_modal=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="access_time" class="cursor-pointer date-btn" @click="show_time_modal=true"/>
			</template>
		</q-input>
		<q-dialog v-model="show_time_modal">
			<q-time
				format24h
				v-model="value_selection"
				mask="HH:mm:ss"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-time>
		</q-dialog>
		<q-select
			v-if="condition_field && (condition_field.field_type == 'SINGLESELECT' || condition_field.field_type == 'MULTISELECT')"
			v-model="condition_value_selection"
			:options="condition_value_options"
			:label="field_group_name + ' / ' + condition_field.label"
			:disable="is_inherited || is_disabled"
			:option-label="(item) => item.value"
			:error="error_messages['has_error_' + condition_field.label]"
			:error-message="error_messages['error_message_' + condition_field.label]"
			class="full-width"
		/>
		<q-select
			v-if="!condition_field"
			v-model="conditions_status_selection"
			:options="statuses"
			label="Select status"
			:disable="is_inherited || is_disabled"
			:error="!conditions_status_selection"
			error-message="Please select a valu."
			class="full-width"
		/>
		<q-btn
			flat
			round
			icon="o_close"
			size="sm"
			@click="deleteCondition()"
			:disable="is_inherited || is_disabled"
		/>
	</q-item>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "RuleConditions",
	props: {
		condition: Object,
		is_inherited: Boolean,
		condition_index: Number,
		is_disabled: Boolean,
		error: Object,
	},
	data() {
		return {
			statuses: ['new', 'active', 'finished'],
			date_time_dialog: false,
			show_date_modal: false,
			show_time_modal: false,
			error_messages: {}
		}
	},
	computed: {
		...mapGetters(['all_fields', 'field_groups']),
		field_group_name:  function(){
			if(Object.entries(this.field_groups).length !== 0 && this.condition.field_group){
				return this.field_groups[this.condition.field_group].title
			}
		},
		condition_field: function() {
			if (this.condition.condition_type == 'Field') {
				return this.all_fields[this.condition.field]
			}
		},
		condition_value_options: function() {
			return this.condition_field.options
		},
		condition_value_selection: {
			get: function() {
				let value_id = this.condition.values[0];
				return this.condition_value_options.filter(option => option.id == value_id)[0];
			},
			set: function(new_val) {
				if (new_val) {
					this.$set(this.condition, "rerender", "render")
					this.$delete(this.condition, "rerender")
					this.$set(this.condition.values, 0, new_val.id)
				}
			}
		},

		conditions_status_selection: {
			get: function() {
				return this.condition.status
			},
			set: function(new_val) {
				this.$set(this.condition, "rerender", "render")
				this.$delete(this.condition, "rerender")
				this.$set(this.condition, "status", new_val);
			}
		},
		value_selection: {
			get: function() {
				return this.condition.values
			},
			set: function(new_val) {
				this.$set(this.condition, "rerender", "render")
				this.$delete(this.condition, "rerender")
				this.$set(this.condition, 'values', new_val);
			}
		},
	},
	methods: {
		resetErrors(){
			this.error_messages['has_error_' + this.condition_field.label] = false;
			this.error_messages['error_message_' + this.condition_field.label] = ''
		},
		deleteCondition(){
			if(!this.is_inherited) {
				if(this.condition.added){
					this.$emit("deleteConditon", true)
				}
				else{
					this.$emit("deleteConditon", false)
				}
			}
		},
	},
	watch:{
		error:{
			deep: true,
			handler () {
				if (this.error.has_error && this.error.error_type == 'condition' && this.condition_field){
					if(this.condition.values == this.error.error_value || (typeof(this.condition.values) == 'object'
						&& JSON.stringify(this.condition.values) === JSON.stringify(this.error.error_value))
					){
						this.error_messages['has_error_' + this.error.error_field_name] = true;
						this.error_messages['error_message_' + this.error.error_field_name] = this.error.error_message
						this.$forceUpdate();
					}

				}
				if (this.condition_field && this.error_messages['has_error_' + this.condition_field.label]
					&& !this.error.has_error
				){
					this.resetErrors()
					this.$forceUpdate();
				}
			}
		},
	},
	created(){
		if (this.condition_field){ this.resetErrors() }
	}
}
</script>
<style lang="scss" scoped>
	.date-time-container{
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.date-time{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
</style>