<template>
	<q-item
		:class="
			['action_value_attachment', 'action_value_checkbox'].includes(current_display) ? 
			'flex items-center' : 'flex items-baseline q-pa-none'
		"
		:tag="['action_value_attachment', ''].includes(current_display) ? 'label' : ''"
	>
		<q-select v-if="current_display === 'action_type'"
			outlined
			class="full-width"
			v-model="action_selected"
			:options="action_list"
			label="Add new action"
			:disable="is_inherited"
			@input="selectRule"
			@popup-hide="openItems()"
		/>

		<q-select v-if="current_display === 'action_fg'"
			outlined
			class="full-width"
			v-model="action_fg_selection"
			:options="fg_options"
			:label="title_text"
			:disable="is_inherited"
			ref="action_fg"
			@popup-hide="openItems()"
			:option-label="(item) => item.title"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>

		<q-select v-if="current_display === 'action_field'"
			outlined
			class="full-width"
			v-model="action_field_selection"
			:options="field_options"
			:label="title_text"
			:disable="is_inherited"
			ref="action_field"
			@popup-hide="openItems()"
			:option-label="(item) => item.label"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>

		<q-select v-if="current_display === 'action_value_multiselect'"
			outlined
			class="full-width"
			v-model="action_value_selection"
			:options="value_options"
			:label="action_field_selection.label + ' / ' + title_text"
			:disable="is_inherited"
			ref="action_value_multiselect"
			@popup-hide="openItems()"
			:error="has_action_select_error"
			:error-message="action_select_error_message"
			:option-label="(item) => item.value"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>

		<div class="text-left full-width row " v-if="current_display == 'action_value_checkbox'">
			<div style="width:50%;" class="flex items-center">{{ action_field_selection.label }} / {{ title_text }}</div>
			<div style="width:50%;" class="text-right">
				<q-toggle v-model="action_checkbox_value" :disable="is_inherited"/>
			</div>
		</div>
		<!-- ATTACHMENT -->
		<div class="text-left full-width row items-center" style="height: 100%;" v-if="current_display == 'action_value_attachment'">
			<div style="width:70%;" class="flex items-center">
				{{ action_field_selection.label }} / {{ title_text }}
			</div>
			<div style="width:30%;" class="text-right">
				<q-toggle v-model="action_attachment_value" :disable="is_inherited"/>
			</div>
		</div>
		<q-input v-if="current_display == 'action_value_datetime'"
			outlined
			v-model="action_input_value"
			class="full-width"
			type="text"
			:label="action_field_selection.label + ' / ' + title_text"
			:disable="is_inherited"
			:error="has_action_input_error"
			:error-message="action_input_error_message"
			hint="Format DD/MM/YYYY HH:MM:SS"
			@click="date_time_dialog=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="calendar_today" class="cursor-pointer date-btn" @click="date_time_dialog=true"/>
			</template>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>

		<!-- DATE -->
		<q-input v-if="current_display == 'action_value_date'"
			outlined
			v-model="action_input_value"
			class="full-width"
			type="text"
			:label="action_field_selection.label + ' / ' + title_text"
			:disable="is_inherited"
			:error="has_action_input_error"
			:error-message="action_input_error_message"
			hint="Format DD/MM/YYYY"
			@click="show_date_modal=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="event" class="cursor-pointer date-btn" @click="show_date_modal=true"/>
			</template>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<!-- TIME -->
		<q-input v-if="current_display == 'action_value_time'"
			outlined
			v-model="action_input_value"
			class="full-width"
			type="text"
			:label="action_field_selection.label + ' / ' + title_text"
			:disable="is_inherited"
			:error="has_action_input_error"
			:error-message="action_input_error_message"
			hint="Format HH:MM:SS"
			@click="show_time_modal=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="access_time" class="cursor-pointer date-btn" @click="show_time_modal=true"/>
			</template>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<!-- NUMBER -->
		<q-input v-if="current_display == 'action_value_number'"
			outlined
			v-model="action_input_value"
			type="number"
			class="full-width"
			:label="action_field_selection.label + ' / ' + title_text"
			:disable="is_inherited"
			:error="has_action_input_error"
			:error-message="action_input_error_message"
			onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<!-- INPUT FIELD -->
		<q-input v-if="current_display == 'action_value_input'"
			outlined
			v-model="action_input_value"
			class="full-width"
			type="text"
			:label="action_field_selection.label + ' / ' + title_text"
			:error="has_action_input_error"
			:error-message="action_input_error_message"
			:disable="is_inherited"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>

		<!-- STATUS -->
		<q-select v-if="current_display == 'action_status'"
			outlined
			class="full-width"
			v-model="actions_status_selection"
			:options="statuses"
			:label="title_text"
			:disable="is_inherited"
			ref="action_status"
			:error="has_action_status_error"
			:error-message="action_status_error_message"
			@popup-hide="openItems()"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>
		<!-- SCENARIO TYPE SELECT -->
		<div class="full-width" v-if="current_display == 'action_scenario'">
			<q-select v-if="!selected_scenario_type.length"
				outlined
				class="full-width"
				v-model="selected_scenario_type"
				:options="scenario_types"
				label="Select scenario type"
				:disable="is_inherited"
				ref="action_scenario_type"
				@popup-hide="openItems()"
			>
				<template v-slot:append>
					<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
				</template>
			</q-select>
			<q-select v-else
				outlined
				class="full-width"
				v-model="actions_scenario_selection"
				:options="scenarios_filtered"
				:label="'Select ' + selected_scenario_type + ' scenario' "
				:disable="is_inherited"
				ref="action_scenario_list"
				@popup-hide="openItems()"
				:error="has_action_scenario_error"
				:error-message="action_scenario_error_message"
				:option-label="(item) => item.name"
			>
				<template v-slot:append>
					<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
				</template>
			</q-select>
		</div>
		<q-btn
			v-if="['action_status', 'action_value_input', 'action_value_date', 'action_value_time',
				'action_value_datetime', 'action_value_checkbox', 'action_value_number',
				'action_value_multiselect', 'action_value_attachment'].includes(current_display) || 
				(current_display == 'action_scenario' && selected_scenario_type.length)
			"
			round
			flat
			color="dark"
			icon="check"
			size="sm"
			:disable="is_inherited"
			style="height: fit-content;"
			@click="action_accept_value()"
		/>
		<q-btn
			v-if="['action_value_checkbox', 'action_value_attachment'].includes(current_display)"
			round
			flat
			color="dark"
			icon="close"
			size="sm"
			:disable="is_inherited"
			style="height: fit-content;"
			@click="resetValues()"
		/>
		<q-dialog v-model="date_time_dialog">
			<div
				class="q-pa-md date-time-container"
				:class="$q.screen.lt.sm ? 'q-px-none q-py-md' : 'q-pa-md'"
				:style="$q.screen.lt.sm ? 'max-width: 88%;' : 'max-width: 1000px;'"
			>
				<div class="q-gutter-md row items-start date-time">
					<q-date
						v-model="action_input_value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
					<q-time
						format24h
						v-model="action_input_value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
				</div>
				<div class="q-mt-md flex justify-end full-width">
					<q-btn color="primary" flat label="Close" @click="date_time_dialog = false" />
				</div>
			</div>
		</q-dialog>
		<q-dialog v-model="show_date_modal">
			<q-date
				today-btn
				v-model="action_input_value"
				mask="DD/MM/YYYY"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-date>
		</q-dialog>
		<q-dialog v-model="show_time_modal">
			<q-time
				format24h
				v-model="action_input_value"
				mask="HH:mm:ss"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-time>
		</q-dialog>
	</q-item>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "RuleactionsAdd",
	props: {
		scenarios: Array,
		is_inherited: Boolean
	},
	data() {
		return {
			action_fg_selection: {},
			action_field_selection: {},
			action_value_selection: {},
			actions_status_selection: '',
			action_checkbox_value: true,
			action_attachment_value: true,
			actions_scenario_selection: {},
			action_input_value: "",
			are_scenarios_real: false,
			selected_scenario_type: '',
			field_options: [],
			value_options: [],
			current_display: "action_type",
			action: {},
			statuses: ['new', 'active', 'finished'],
			action_list: ['Scenario'],
			scenario_types: [],
			action_selected: '',
			date_time_dialog: false,
			show_date_modal: false,
			show_time_modal: false,
			is_add_action_disabled: false,
			has_action_input_error: false,
			has_action_select_error: false,
			has_action_status_error: false,
			has_action_scenario_error: false,
			action_input_error_message: '',
			action_status_error_message: '',
			action_select_error_message: '',
			action_scenario_error_message: '',
		}
	},
	computed: {
		...mapGetters(['field_groups', 'all_fields', 'current_CG', 'logged_user']),
		title_text: function() {
			if (this.current_display == "action_type" ) { return "Select type" }
			if (["action_fg"].includes(this.current_display)) { return "Select field group" }
			if (["action_field"].includes(this.current_display)) { return "Select field" }
			if (this.current_display === 'action_value_input') { return "Input value" }
			if (this.current_display === 'action_value_datetime') { return "Select date and time" }
			if (this.current_display === 'action_value_date') { return "Select date" }
			if (this.current_display === 'action_value_time') { return "Select time" }
			if (this.current_display === 'action_value_number') { return "Input value" }
			if (this.current_display === 'action_value_attachment') { return "Has attachment?" }
			if (this.current_display === 'action_value_checkbox') { return "Select value" }
			if (["action_value_multiselect"].includes(this.current_display)) { return "Select value" }
			if (["action_status"].includes(this.current_display)) {return "Select status"}

		},
		fg_options: function() {
			return Object.values(this.field_groups)
		},
		scenarios_filtered: function() {
			return this.scenarios.filter(scenario =>
				scenario.real == this.are_scenarios_real).map(scenario => ({id: scenario.id, name: scenario.name}))
		},
	},
	watch: {
		action_fg_selection: function(new_sel, old_sel) {
			let selection = new_sel
			if (selection == null) { selection = old_sel }
			if (selection.id){
				this.action.field_group = selection.id;
				this.current_display = "action_field";
				this.field_options = selection.field_mappings.map(field_mapping => field_mapping.field)
			}else{
				this.field_options = {}
			}
		},
		action_field_selection: function(new_sel, old_sel) {
			if (this.current_display  === "action_field"){
				if (new_sel == null) {new_sel = old_sel}
				this.action.field = new_sel.id;
				if (new_sel.field_type === "SINGLESELECT" || new_sel.field_type === "MULTISELECT") {
					this.current_display = "action_value_multiselect";
					this.value_options = new_sel.options
				}
				else if (new_sel.field_type === "CHECKBOX") { this.current_display = "action_value_checkbox" }
				else if(new_sel.field_type === "DATETIME"){ this.current_display = "action_value_datetime" }
				else if(new_sel.field_type === "DATE"){ this.current_display = "action_value_date" }
				else if(new_sel.field_type === "TIME"){ this.current_display = "action_value_time" }
				else if(new_sel.field_type === 'NUMERIC'){ this.current_display = "action_value_number" }
				else if(new_sel.field_type === 'ATTACHMENT'){ this.current_display = "action_value_attachment" }
				else{ this.current_display = "action_value_input" }
			}
		},
		selected_scenario_type: function(new_val){
			if (new_val == "Test"){	this.are_scenarios_real = false	}
			else if (new_val == "Real"){ this.are_scenarios_real = true	}
			else{ this.are_scenarios_real = false }
		}
	},
	methods: {
		selectRule(){
			if (this.action_selected === 'Field'){ this.actionFieldClicked() }
			else if(this.action_selected === 'Status'){ this.actionStatusClicked() }
			else if(this.action_selected === 'Scenario'){	this.actionScenarioClicked() }
		},
		addNewAction() {
			this.action.added = true;
			this.$emit("ruleNewAction", this.action);
			this.action = {};
		},
		actionFieldClicked() {
			this.action.action_type = "Field";
			this.current_display = "action_fg";
		},
		actionStatusClicked() {
			this.action.action_type = "Status"
			this.current_display = "action_status";
		},
		actionScenarioClicked() {
			this.action.action_type =  "Scenario";
			this.current_display = "action_scenario";
		},
		action_accept_value() {
			this.resetErrors()
			if (this.hasError()){ return }
			if (this.current_display === "action_value_multiselect" && this.action_value_selection ){
				this.action.values = [this.action_value_selection.id];
			}else if (this.current_display === "action_status" && this.actions_status_selection){
				this.action.incident_state = this.actions_status_selection;
			}else if (this.current_display == "action_value_checkbox") {
				this.action.values = this.action_checkbox_value;
			}else if(this.current_display == 'action_value_attachment'){
				this.action.values = this.action_attachment_value;
			}else if(this.current_display === "action_scenario" && this.actions_scenario_selection){
				this.action.scenario = this.actions_scenario_selection.id;
			}else if (["action_value_input", "action_value_date", "action_value_time", "action_value_datetime",
			"action_value_number", "action_value_attachment", "action_value_attachment"].includes(this.current_display) && this.action_input_value) {
				this.action.values = this.action_input_value;
			}
			this.addNewAction()
			this.resetValues()
		},
		hasError(){
			if (this.current_display === 'action_status' && !this.actions_status_selection.length){
				this.has_action_status_error = true
				this.action_status_error_message = 'Please select a value'
				return true
			}
			if (
				['action_value_input', 'action_value_date', 'action_value_time',
				'action_value_datetime','action_value_number'].includes(this.current_display)
				&& !this.action_input_value
			){
				this.has_action_input_error = true
				this.action_input_error_message = 'Please enter a value'
				return true
			}
			if (this.current_display === 'action_value_multiselect' && !this.action_value_selection.value){
				this.has_action_select_error = true
				this.action_select_error_message = 'Please select a value'
				return true
			}
			if (this.current_display === 'action_scenario' && this.selected_scenario_type.length && !this.actions_scenario_selection.id){
				this.action_scenario_error_message = 'Please select a scenario'
				this.has_action_scenario_error = true
				return true
			}
			return false
		},
		resetErrors(){
			this.has_action_status_error = this.has_action_input_error = this.has_action_select_error = this.action_select_error_message = false
			this.action_status_error_message = this.action_input_error_message = this.action_select_error_message = this.action_scenario_error_message = ''
		},
		resetValues(){
			this.action_fg_selection = {}
			this.action_value_selection = {}
			this.action_field_selection = {}
			this.actions_status_selection = ""
			this.action_checkbox_value = true
			this.action_attachment_value = true
			this.action_input_value = ""
			this.action_selected = ""
			this.actions_scenario_selection = {};
			this.current_display = "action_type"
			this.selected_scenario_type = ""
		},
		openItems(){
			if (this.current_display === 'action_scenario'){
				if(this.current_display == 'action_scenario' && this.actions_scenario_selection.id){
					return
				}else if (!this.selected_scenario_type.length){
					this.$refs['action_scenario_type'].showPopup()
				}else{
					this.$refs['action_scenario_list'].showPopup()
				}
			}else if (
				!["action_type", "action_value_checkbox", "action_value_input", "action_value_date",
				"action_value_time", "action_value_datetime", "action_value_number", "action_value_attachment"
				].includes(this.current_display)
			){
				if (this.current_display == 'action_value_multiselect' && this.action_value_selection.value){ return }
				if (this.current_display == 'action_status' && this.actions_status_selection){ return }
				this.$refs[this.current_display].showPopup()
			}
		},
	},
	created() {
		if (this.logged_user.customer_permission === "ADMIN"){
			this.action_list.unshift('Field', 'Status')
		}
		this.scenario_types.push(
			this.current_CG.customer_group.session_type_name.test_scenario_name,
			this.current_CG.customer_group.session_type_name.real_scenario_name
		)
	},
}
</script>
<style lang="scss" scoped>
	.date-time-container{
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.date-time{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
</style>