<template>
	<q-card
		:class="{
			'border-left': selected_rule.id == rule.id,
			'q-mb-md': true,
		}"
		:id="`rule_${rule.id}`"
	>
		<q-card-section class="q-pa-sm">
			<div class="row">
				<div
					:class="{
						'grey items-end flex break': true,
						'col-2 text-center justify-center': $q.screen.gt.xs,
						'col-12 text-left justify-start': $q.screen.lt.sm,
					}"
				>Conditions</div>
				<div
					:class="{
						'items-center': true,
						'col-10': $q.screen.gt.xs,
						'col-12': $q.screen.lt.sm,
					}"
				>
					<div class="col-12 row items-center">
						<q-list
							v-for="(condition, index) in rule.conditions"
							:key="condition.added_rerender"
							:class="{
								'display-none': condition.deleted,
								'col-md-6 col-12 fixed-height flex items-center': true,
							}"
						>
							<Condition
								:condition="condition"
								:condition_index="index"
								:rule_index="rule_index"
								:is_inherited="is_inherited"
								:is_disabled="is_disabled"
								:error="error"
								@deleteConditon="deleteConditon($event, index)"
							/>

						</q-list>
					</div>
					<div class="row flex items-center fixed-height">
						<AddCondition
							class="col-md-6 col-12"
							:is_inherited="is_inherited"
							@ruleNewCondition="ruleNewCondition"
							:is_disabled="is_disabled"
						/>
					</div>
				</div>
			</div>
			<div class="row">
				<div
					:class="{
						'items-center': true,
						'col-2': $q.screen.gt.xs,
						'col-12': $q.screen.lt.sm,
					}"
				>
					<q-icon color="primary" class="arrow" name="south"/>
				</div>
			</div>
			<div class="row full-width">
				<div
					:class="{
						'grey items-baseline flex break': true,
						'col-2 text-center justify-center': $q.screen.gt.xs,
						'col-12 text-left justify-start': $q.screen.lt.sm,
					}"
				>Actions</div>
				<div
					:class="{
						'items-center': true,
						'col-10': $q.screen.gt.xs,
						'col-12': $q.screen.lt.sm,
					}"
				>
					<div class="col-12 row items-center">
						<q-list
							v-for="(action, index) in rule.actions"
							:key="index"
							:class="{'display-none': action.deleted}"
							class="col-md-6 col-12 fixed-height flex items-center"
						>
							<Action
								:action="action"
								:action_index="index"
								:rule_index="rule_index"
								:scenarios="scenarios"
								:is_inherited="is_inherited"
								:is_disabled="is_disabled"
								:error="error"
								@deleteAction="deleteAction($event, index)"
							/>

						</q-list>
					</div>
					<div class="row flex items-center fixed-height ">
						<AddAction
							class="col-md-6 col-12"
							:scenarios="scenarios"
							:is_inherited="is_inherited"
							@ruleNewAction="ruleNewAction"
						/>
					</div>
				</div>
			</div>
		</q-card-section>
		<q-card-section class="text-left full-width q-px-sm q-pb-sm q-pt-none">
			<q-item tag="label" class="full-width row">
				<div style="width:50%;" class="flex items-center">Trigger rule on each invocation</div>
				<div style="width:50%;" class="text-right">
					<q-toggle v-model="rule.trigger_on_each_invocation" :disable="is_inherited || is_disabled"/>
				</div>
			</q-item>
		</q-card-section>
	</q-card>
</template>

<script>
import Condition from '@/components/incidents/rules/RuleConditions.vue';
import Action from '@/components/incidents/rules/RuleActions.vue';
import AddCondition from '@/components/incidents/rules/RuleConditionsAdd.vue';
import AddAction from '@/components/incidents/rules/RuleActionsAdd.vue';
import { mapGetters } from "vuex";

export default {
	name: "Rule",
	components: {
		Condition,
		Action,
		AddCondition,
		AddAction,
	},
	props: {
		rule: Object,
		rule_index: Number,
		form_id: Number,
		scenarios: Array,
		form: Object,
		counter: Number,
		is_inherited: Boolean,
		selected_rule: Object,
		error: Object,
	},
	data() {
		return {
			all_conditions_deleted: false,
			all_actions_deleted: false,
			is_disabled: false,
		}
	},
	computed: {
		...mapGetters(['rules', 'logged_user']),
		rule_modified: function() {
			let rule_index = null
			for (let i in this.rules) {
				if (this.rules[i].id === this.rule.id) {
					rule_index = i;
				}
			}

			let condition_modified = false;
			let action_modified = false;
			if (rule_index) {
				this.all_conditions_deleted = true;
				this.all_actions_deleted = true;
				// Check conditions
				if(this.rule.conditions.length != this.rules[rule_index].conditions.length){
					condition_modified = true;
					this.all_conditions_deleted = false;
				}
				for(let i in this.rule.conditions){
					if(!this.rules[rule_index].conditions[i]){
						continue;
					}
					this.all_conditions_deleted = this.all_conditions_deleted && this.rule.conditions[i].deleted
					this.rule.conditions[i].modified = false;
					let modified_c = false;
					if(this.rule.conditions[i].deleted){
						condition_modified = true;
						this.rule.conditions[i].deleted = true;
					}
					if (this.rule.conditions[i].values != undefined) {
						if (Array.isArray(this.rule.conditions[i].values) && Array.isArray(this.rules[rule_index].conditions[i].values)) {
							modified_c = this.rule.conditions[i].values[0] != this.rules[rule_index].conditions[i].values[0];
							if (modified_c){
								this.rule.conditions[i].modified = true;
								condition_modified = true;
							}
						}
						else {
							modified_c = this.rule.conditions[i].values != this.rules[rule_index].conditions[i].values;
							if (modified_c){
								this.rule.conditions[i].modified = true;
								condition_modified = true;
							}
						}
					}
					else if (this.rule.conditions[i].status) {
						modified_c = this.rule.conditions[i].status != this.rules[rule_index].conditions[i].status;
						if (modified_c){
							this.rule.conditions[i].modified = true;
							condition_modified = true;
						}
					}
				}

				// Check Actions

				if(this.rule.actions.length != this.rules[rule_index].actions.length){
					action_modified = true;
					this.all_actions_deleted = false;
				}
				for(let i in this.rule.actions){
					if(!this.rules[rule_index].actions[i]){
						continue;
					}
					this.all_actions_deleted = this.all_actions_deleted && this.rule.actions[i].deleted;
					this.rule.actions[i].modified = false;
					let modified_a = false;
					if(this.rule.actions[i].deleted){
						action_modified = true;
						this.rule.actions[i].deleted = true;
					}
					if (this.rule.actions[i].values != undefined) {
						if (Array.isArray(this.rule.actions[i].values) && Array.isArray(this.rules[rule_index].actions[i].values)) {
							modified_a = this.rule.actions[i].values[0] != this.rules[rule_index].actions[i].values[0];
							if (modified_a){
								this.rule.actions[i].modified = true;
								action_modified = true;
							}
						}
						else {
							modified_a = this.rule.actions[i].values != this.rules[rule_index].actions[i].values;
							if (modified_a){
								this.rule.actions[i].modified = true;
								action_modified = true;
							}
						}
					}
					else if (this.rule.actions[i].incident_state) {
						modified_a = this.rule.actions[i].incident_state != this.rules[rule_index].actions[i].incident_state;
						if (modified_a){
							this.rule.actions[i].modified = true;
							action_modified = true;
						}
					}
					else if (this.rule.actions[i].scenario) {
						action_modified = this.rule.actions[i].scenario != this.rules[rule_index].actions[i].scenario;
					}
				}
			}

			if (condition_modified || action_modified) {
				this.$set(this.rule, "modified", true);
				return true
			}
			else {
				this.$delete(this.rule, "modified");
				return false
			}
		},
	},
	methods: {
		documentClick(e) {
			let new_rule = e.target.closest("div[id^=rule]")
			if (new_rule && (this.selected_rule.id == new_rule.id.split('_').pop())){ return }
			if (new_rule && new_rule.id){
				this.$emit('rule-selected', new_rule.id)
			}
		},
		ruleNewCondition(new_condition){
			this.rule.conditions.push(new_condition)
		},
		ruleNewAction(new_action){
			this.rule.actions.push(new_action)
		},
		deleteConditon(added, index){
			if(added){
				this.rule.conditions.splice(index, 1)
				return;
			}
			if(this.rule.conditions[index].deleted){
				this.$delete(this.rule.conditions[index], "deleted");
			}
			else{
				this.$set(this.rule.conditions[index], 'deleted', true)
			}
		},
		deleteAction(added, index){
			if(added){
				this.rule.actions.splice(index, 1)
				return;
			}
			if(this.rule.actions[index].deleted){
				this.$delete(this.rule.actions[index], "deleted");
			}
			else{
				this.$set(this.rule.actions[index], 'deleted', true)
			}
		},
	},
	async created() {
		this.scenarios.forEach(scenario => {
			if (scenario.name.length > 10) {
				this.$set(scenario, "short_name", scenario.name.slice(0,10).concat("..."))
			}
			else {
				this.$set(scenario, "short_name", scenario.name)
			}
		});
		document.addEventListener('click', this.documentClick);

		for (let action of this.rule.actions) {
			if (this.logged_user.customer_permission === 'OPERATOR' && action.action_type != 'Scenario') {
				this.is_disabled = true
				break
			}
		}
		this.$q.loading.hide()
	},
	destroyed() {
		document.removeEventListener('click', this.documentClick);
	}
}
</script>

<style lang="scss" scoped>
.break{
	word-break: break-all;
}
.border-left{
	border-left: 5px solid var(--q-color-primary);
}
.border-top{
	border-top: 1px solid $grey-4;
}
.display-none{
	display: none;
}
.grey{
	color: $grey-8;
	font-size: 14px;
}
.arrow{
	color: $grey-8;
    font-size: 15px;
	text-align: center;
}
.fixed-height{
	min-height: 76px;
	@media(max-width: 599px) {
		min-height: auto;
	}
}
</style>
<style lang="scss">
.date-btn .q-btn__wrapper, .close-btn .q-btn__wrapper {
	padding: 0.5rem;
	font-size: 12px,
}
</style>