<template>
	<q-item
		class="full-width flex items-center q-py-none"
		:tag="action_field && ['CHECKBOX', 'ATTACHMENT'].includes(action_field.field_type) ? 'label' : ''"
	>
		<q-input
			v-if="action_field && (
				action_field.field_type == 'TEXT' ||
				action_field.field_type == 'EMAIL'
			)
		"
			v-model="value_selection"
			:label="field_group_name + ' / ' + action_field.label"
			:disable="is_inherited || is_disabled"
			:error="error_messages['has_error_' + action_field.label]"
			:error-message="error_messages['error_message_' + action_field.label]"
			class="full-width"
		/>
		<div class="text-left full-width row " v-else-if="action_field && action_field.field_type == 'CHECKBOX'">
			<div style="width:50%;" class="flex items-center justify-start">{{field_group_name}} / {{action_field.label}}</div>
			<div style="width:50%;" class="flex items-center justify-end">
				<q-toggle v-model="value_selection" :disable="is_inherited || is_disabled"/>
			</div>
		</div>
		<div class="text-left full-width row " v-else-if="action_field && action_field.field_type == 'ATTACHMENT'">
			<div style="width:50%;" class="flex items-center justify-start">{{field_group_name}} / {{action_field.label}} has attachment?</div>
			<div style="width:50%;" class="flex items-center justify-end">
				<q-toggle v-model="value_selection" :disable="is_inherited || is_disabled"/>
			</div>
		</div>

		<!-- NUMBER -->
		<q-input
			v-else-if="action_field && action_field.field_type == 'NUMERIC'"
			class="full-width"
			type="number"
			v-model="value_selection"
			:label="field_group_name + ' / ' + action_field.label"
			:disable="is_inherited || is_disabled"
			:error="error_messages['has_error_' + action_field.label]"
			:error-message="error_messages['error_message_' + action_field.label]"
			onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
		/>
		<!-- DATETIME -->
		<q-input v-else-if="action_field && action_field.field_type == 'DATETIME'"
			class="full-width"
			v-model="value_selection"
			:label="field_group_name + ' / ' + action_field.label"
			:disable="is_inherited || is_disabled"
			:error="error_messages['has_error_' + action_field.label]"
			:error-message="error_messages['error_message_' + action_field.label]"
			hint="Format DD/MM/YYYY HH:MM:SS"
			@click="date_time_dialog = true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="calendar_today" class="cursor-pointer date-btn" @click="date_time_dialog=true"/>
			</template>
		</q-input>
		<!-- DATE-->
		<q-input v-else-if="action_field && action_field.field_type == 'DATE'"
			v-model="value_selection"
			class="full-width"
			:label="field_group_name + ' / ' + action_field.label"
			:disable="is_inherited || is_disabled"
			:error="error_messages['has_error_' + action_field.label]"
			:error-message="error_messages['error_message_' + action_field.label]"
			hint="Format DD/MM/YYYY"
			@click="show_date_modal = true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="event" class="cursor-pointer date-btn" @click="show_date_modal=true"/>
			</template>
		</q-input>
		<!-- TIME -->
		<q-input v-else-if="action_field && action_field.field_type == 'TIME'"
			v-model="value_selection"
			class="full-width"
			:label="field_group_name + ' / ' + action_field.label"
			:disable="is_inherited || is_disabled"
			:error="error_messages['has_error_' + action_field.label]"
			:error-message="error_messages['error_message_' + action_field.label]"
			hint="Format HH:MM:SS"
			@click="show_time_modal=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="access_time" class="cursor-pointer date-btn" @click="show_time_modal=true"/>
			</template>
		</q-input>
		<!-- SELECT -->
		<q-select
			v-else-if="action_field && (action_field.field_type == 'SINGLESELECT' || action_field.field_type == 'MULTISELECT')"
			v-model="action_value_selection"
			:options="action_value_options"
			:label="field_group_name + ' / ' + action_field.label"
			:disable="is_inherited || is_disabled"
			:option-label="(item) => item.value"
			:error="error_messages['has_error_' + action_field.label]"
			:error-message="error_messages['error_message_' + action_field.label]"
			class="full-width"
		/>
		<q-select v-else-if="action.action_type == 'Status'"
			v-model="actions_status_selection"
			:options="statuses"
			:disable="is_inherited || is_disabled"
			:error="!actions_status_selection"
			error-message="Please select a value"
			label="Status"
			class="full-width"
		/>
		<q-select v-else-if="action.action_type == 'Scenario'"
			v-model="actions_scenario_selection"
			:options="scenarios"
			:disable="is_inherited || is_disabled"
			:option-label="(item) => item. name"
			:error="!actions_scenario_selection"
			error-message="Please select a value"
			label="Run Scenario"
			class="full-width"
		/>
		<q-btn
			flat
			round
			icon="o_close"
			size="sm"
			@click="deleteAction()"
			:disable="is_inherited || is_disabled"
		/>
		<q-dialog v-model="date_time_dialog">
			<div
				class="q-pa-md date-time-container"
				:class="$q.screen.lt.sm ? 'q-px-none q-py-md' : 'q-pa-md'"
				:style="$q.screen.lt.sm ? 'max-width: 88%;' : 'max-width: 1000px;'"
			>
				<div class="q-gutter-md row items-start date-time">
					<q-date
						v-model="value_selection"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
					<q-time
						format24h
						v-model="value_selection"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
				</div>
				<div class="q-mt-md flex justify-end full-width">
					<q-btn color="primary" flat label="Close" @click="date_time_dialog = false" />
				</div>
			</div>
		</q-dialog>
		<q-dialog v-model="show_date_modal">
			<q-date
				today-btn
				v-model="value_selection"
				mask="DD/MM/YYYY"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-date>
		</q-dialog>
		<q-dialog v-model="show_time_modal">
			<q-time
				format24h
				v-model="value_selection"
				mask="HH:mm:ss"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-time>
		</q-dialog>
	</q-item>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	name: "Ruleactions",
	props: {
		action: Object,
		scenarios: Array,
		is_inherited: Boolean,
		is_disabled: Boolean,
		rule_index: Number,
		error: Object,
	},
	data() {
		return {
			statuses: ['new', 'active', 'finished'],
			date_time_dialog: false,
			show_date_modal: false,
			show_time_modal: false,
			error_messages: {}
		}
	},
	computed: {
		...mapGetters(['all_fields', 'field_groups']),
		field_group_name:  function(){
			if(Object.entries(this.field_groups).length !== 0)
				return this.field_groups[this.action.field_group].title;
			return ""
		},
		action_field: function() {
			if (this.action.action_type == 'Field') {
				return this.all_fields[this.action.field]
			}
		},
		action_value_options: function() {
			return this.action_field.options
		},
		action_value_selection: {
			get: function() {
				let value_id = this.action.values[0];
				return this.action_value_options.filter(option => option.id == value_id)[0];
			},
			set: function(new_val) {
				if (new_val) {
					this.$set(this.action, "rerender", "render")
					this.$delete(this.action, "rerender")
					this.$set(this.action.values, 0, new_val.id)
				}
			}
		},
		actions_status_selection: {
			get: function() {
				return this.action.incident_state
			},
			set: function(new_val) {
				this.$set(this.action, "rerender", "render")
				this.$delete(this.action, "rerender")
				this.$set(this.action, "incident_state", new_val);
			}
		},
		actions_scenario_selection: {
			get: function() {
				let scenario_id = this.action.scenario;
				return this.scenarios.filter(scenario => scenario.id == scenario_id)[0];
			},
			set: function(new_sel) {
				if (new_sel) {
					this.$set(this.action, "rerender", "render");
					this.$delete(this.action, "rerender");
					this.$set(this.action, "scenario", new_sel.id)
				}
			}
		},
		value_selection: {
			get: function() {
				return this.action.values
			},
			set: function(new_val) {
				this.$set(this.action, "rerender", "render")
				this.$delete(this.action, "rerender")
				this.$set(this.action, 'values', new_val);
			}
		},
	},
	methods: {
		...mapActions(['retrieveFieldGroups']),
		deleteAction(){
			if(!this.is_inherited) {
				if(this.action.added){
					this.$emit("deleteAction",true)
				}
				else{
					this.$emit("deleteAction",false)
				}
			}
		},
		resetErrors(){
			this.error_messages['has_error_' + this.action_field.label] = false;
			this.error_messages['error_message_' + this.action_field.label] = ''
		},
	},
	watch:{
		error:{
			deep: true,
			handler () {

				if (this.error.has_error && this.error.error_type == 'action' && this.action_field){
					if(this.action.values == this.error.error_value || (typeof(this.action.values) == 'object'
						&& JSON.stringify(this.action.values) === JSON.stringify(this.error.error_value))
					){
						this.error_messages['has_error_' + this.error.error_field_name] = true;
						this.error_messages['error_message_' + this.error.error_field_name] = this.error.error_message
						this.$forceUpdate();
					}
				}
				if (this.action_field && this.error_messages['has_error_' + this.action_field.label]
					&& !this.error.has_error
				){
					this.resetErrors()
					this.$forceUpdate();
				}
			}
		},
	},
	created(){
		if (this.action_field){ this.resetErrors() }
	}
}
</script>
<style lang="scss" scoped>
	.date-time-container{
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.date-time{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
</style>