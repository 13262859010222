<template>
	<q-item
		:class="
			['condition_value_attachment', 'condition_value_checkbox'].includes(current_display) ? 
			'flex items-center ' : 'flex items-baseline q-pa-none'
		"
		:tag="['condition_value_attachment', 'condition_value_checkbox'].includes(current_display) ? 'label' : ''"
	>
		<q-select v-if="current_display === 'condition_type'"
			outlined
			class="full-width q-pa-none"
			v-model="selected_condition"
			:options="conditions_list"
			label="Add new condition"
			:disable="is_inherited || is_disabled"
			@input="selectCondition"
		/>
		<q-select v-if="current_display === 'condition_fg'"
			outlined
			class="full-width"
			v-model="condition_fg_selection"
			:options="fg_options"
			:label="title_text"
			:disable="is_inherited || is_disabled"
			ref="condition_fg"
			@popup-hide="openItems()"
			:option-label="(item) => item.title"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>
		<q-select v-if="current_display === 'condition_field'"
			outlined
			class="full-width"
			v-model="condition_field_selection"
			:options="field_options"
			:label="title_text"
			:disable="is_inherited || is_disabled"
			ref="condition_field"
			@popup-hide="openItems()"
			:option-label="(item) => item.label"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>
		<q-select v-if="current_display === 'condition_value_multiselect'"
			outlined
			class="full-width"
			v-model="condition_value_selection"
			:options="value_options"
			:label="condition_field_selection.label + ' / ' + title_text"
			:disable="is_inherited || is_disabled"
			ref="condition_value_multiselect"
			:error="has_select_error"
			:error-message="select_error_message"
			@popup-hide="openItems()"
			:option-label="(item) => item.value"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>
		<div class="text-left full-width row " v-if="current_display == 'condition_value_checkbox'">
			<div style="width:50%;" class="flex items-center">{{ condition_field_selection.label }} / {{ title_text }}</div>
			<div style="width:50%;" class="text-right">
				<q-toggle v-model="condition_checkbox_value" :disable="is_inherited || is_disabled"/>
			</div>
		</div>
		<!-- DATETIME -->
		<q-input v-if="current_display == 'condition_value_datetime'"
			outlined
			v-model="condition_input_value"
			class="full-width"
			type="text"
			:label="condition_field_selection.label + ' / ' + title_text"
			:disable="is_inherited || is_disabled"
			:error="has_input_error"
			:error-message="input_error_message"
			hint="Format DD/MM/YYYY HH:MM:SS"
			@click="date_time_dialog=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="calendar_today" class="cursor-pointer date-btn" @click="date_time_dialog=true"/>
			</template>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<!-- DATE -->
		<q-input v-if="current_display == 'condition_value_date'"
			outlined
			v-model="condition_input_value"
			class="full-width"
			type="text"
			:label="condition_field_selection.label + ' / ' + title_text"
			:disable="is_inherited || is_disabled"
			:error="has_input_error"
			:error-message="input_error_message"
			hint="Format DD/MM/YYYY"
			@click="show_date_modal=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="event" class="cursor-pointer date-btn" @click="show_date_modal=true"/>
			</template>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<!-- TIME -->
		<q-input v-if="current_display == 'condition_value_time'"
			outlined
			v-model="condition_input_value"
			class="full-width"
			type="text"
			:label="condition_field_selection.label + ' / ' + title_text"
			:disable="is_inherited || is_disabled"
			:error="has_input_error"
			:error-message="input_error_message"
			hint="Format HH:MM:SS"
			@click="show_time_modal=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="access_time" class="cursor-pointer date-btn" @click="show_time_modal=true"/>
			</template>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<!-- ATTACHMENT -->
		<div class="text-left full-width row items-center" style="height: 100%;" v-if="current_display == 'condition_value_attachment'">
			<div style="width:70%;" class="flex items-center">
				{{ condition_field_selection.label }} / {{ title_text }}
			</div>
			<div style="width:30%;" class="text-right">
				<q-toggle v-model="condition_attachment_value" :disable="is_inherited || is_disabled"/>
			</div>
		</div>
		<!-- NUMBER -->
		<q-input v-if="current_display == 'condition_value_number'"
			outlined
			v-model="condition_input_value"
			type="number"
			class="full-width"
			:label="condition_field_selection.label + ' / ' + title_text"
			:disable="is_inherited || is_disabled"
			:error="has_input_error"
			:error-message="input_error_message"
			onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<q-input v-if="current_display == 'condition_value_input'"
			outlined
			v-model="condition_input_value"
			class="full-width"
			type="text"
			:label="condition_field_selection.label + ' / ' + title_text"
			:error="has_input_error"
			:error-message="input_error_message"
			:disable="is_inherited || is_disabled"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<q-select v-if="current_display == 'condition_status'"
			outlined
			class="full-width"
			v-model="conditions_status_selection"
			:options="statuses"
			:label="title_text"
			:disable="is_inherited || is_disabled"
			ref="condition_status"
			:error="has_condition_status_error"
			:error-message="condition_status_error_message"
			@popup-hide="openItems()"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>
		<q-btn
			v-if="[
				'condition_status',
				'condition_value_input',
				'condition_value_date',
				'condition_value_time',
				'condition_value_datetime',
				'condition_value_checkbox',
				'condition_value_number',
				'condition_value_attachment',
				'condition_value_multiselect'
			].includes(current_display)"
			round
			flat
			color="dark"
			icon="check"
			size="sm"
			:disable="is_inherited || is_disabled"
			style="height: fit-content;"
			@click="condition_accept_value()"
		/>
		<q-btn
			v-if="['condition_value_checkbox', 'condition_value_attachment'].includes(current_display)"
			round
			flat
			color="dark"
			icon="close"
			size="sm"
			:disable="is_inherited || is_disabled"
			style="height: fit-content;"
			@click="resetValues()"
		/>
		<q-dialog v-model="show_time_modal">
			<q-time
				format24h
				v-model="condition_input_value"
				mask="HH:mm:ss"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-time>
		</q-dialog>
		<q-dialog v-model="show_date_modal">
			<q-date
				today-btn
				v-model="condition_input_value"
				mask="DD/MM/YYYY"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-date>
		</q-dialog>
		<q-dialog v-model="date_time_dialog">
			<div
				class="q-pa-md date-time-container"
				:class="$q.screen.lt.sm ? 'q-px-none q-py-md' : 'q-pa-md'"
				:style="$q.screen.lt.sm ? 'max-width: 88%;' : 'max-width: 1000px;'"
			>
				<div class="q-gutter-md row items-start date-time">
					<q-date
						v-model="condition_input_value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
					<q-time
						format24h
						v-model="condition_input_value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
				</div>
				<div class="q-mt-md flex justify-end full-width">
					<q-btn color="primary" flat label="Close" @click="date_time_dialog = false" />
				</div>
			</div>
		</q-dialog>
	</q-item>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "RuleConditionsAdd",
	props: {
		is_inherited: Boolean,
		is_disabled: Boolean,
	},
	data() {
		return {
			condition_fg_selection: {},
			condition_field_selection: {},
			condition_value_selection: {},
			conditions_status_selection: '',
			condition_checkbox_value: true,
			condition_attachment_value: true,
			condition_input_value: "",
			field_options: [],
			value_options: [],
			current_display: "condition_type",
			condition: {},
			statuses: ['new', 'active', 'finished'],
			conditions_list: ['Field', 'Status'],
			selected_condition: '',
			date_time_dialog: false,
			show_date_modal: false,
			show_time_modal: false,
			has_condition_status_error: false,
			has_input_error: false,
			has_select_error: false,
			condition_status_error_message: '',
			input_error_message: '',
			select_error_message: '',
		}
	},
	computed: {
		...mapGetters(['field_groups', 'all_fields']),
		title_text: function() {
			if (this.current_display == "condition_type" ) {
				return "Select Type"
			}
			if (["condition_fg"].includes(this.current_display)) { return "Select field group" }
			if (["condition_field"].includes(this.current_display)) { return "Select field" }
			if (this.current_display === 'condition_value_input') { return "Input value" }
			if (this.current_display === 'condition_value_datetime') { return "Select date and time" }
			if (this.current_display === 'condition_value_date') { return "Select date" }
			if (this.current_display === 'condition_value_time') { return "Select time" }
			if (this.current_display === 'condition_value_number') { return "Input value" }
			if (this.current_display === 'condition_value_attachment') { return "Has attachment?" }
			if (this.current_display === 'condition_value_checkbox') { return "Select value" }
			if (["condition_value_multiselect"].includes(this.current_display)) { return "Select value" }
			if (["condition_status"].includes(this.current_display)) {return "Select status"}

		},
		fg_options: function() {
			return Object.values(this.field_groups)
		},
	},
	watch: {
		condition_fg_selection: function(new_sel, old_sel) {
			let selection = new_sel
			if (selection == null) { selection = old_sel }
			if (selection.id){
				this.condition.field_group = selection.id;
				this.current_display = "condition_field";
				this.field_options = selection.field_mappings.map(field_mapping => field_mapping.field)
			}else{
				this.field_options = {}
			}
		},
		condition_field_selection: function(new_sel, old_sel) {
			if (this.current_display  === "condition_field"){
				if (new_sel == null) {new_sel = old_sel}
				this.condition.field = new_sel.id;
				if (new_sel.field_type === "SINGLESELECT" || new_sel.field_type === "MULTISELECT") {
					this.current_display = "condition_value_multiselect";
					this.value_options = new_sel.options
				}
				else if (new_sel.field_type === "CHECKBOX") { this.current_display = "condition_value_checkbox" }
				else if(new_sel.field_type === "DATETIME"){ this.current_display = "condition_value_datetime" }
				else if(new_sel.field_type === "DATE"){ this.current_display = "condition_value_date" }
				else if(new_sel.field_type === "TIME"){ this.current_display = "condition_value_time" }
				else if(new_sel.field_type === 'NUMERIC'){ this.current_display = "condition_value_number" }
				else if (new_sel.field_type === 'ATTACHMENT'){this.current_display = "condition_value_attachment"}
				else{ this.current_display = "condition_value_input" }
			}
		},
	},
	methods: {
		addNewCondition() {
			this.condition.added = true;
			this.$emit("ruleNewCondition", this.condition);
			this.condition = {};
		},
		selectCondition(){
			if (this.selected_condition == 'Field'){
				this.conditionFieldClicked()
			}else if(this.selected_condition == 'Status'){
				this.conditionStatusClicked()
			}
		},
		conditionFieldClicked() {
			this.condition.condition_type = "Field";
			this.current_display = "condition_fg";
		},
		conditionStatusClicked() {
			this.condition.condition_type = "Status"
			this.current_display = "condition_status";
		},

		condition_accept_value() {
			this.resetErrors()
			if (this.hasError()){ return }
			if (this.current_display === "condition_value_multiselect" && this.condition_value_selection ){
				this.condition.values = [this.condition_value_selection.id];
			}else if (this.current_display === "condition_status" && this.conditions_status_selection){
				this.condition.status = this.conditions_status_selection;
			}else if (this.current_display == "condition_value_checkbox") {
				this.condition.values = this.condition_checkbox_value;
			}else if(this.current_display == "condition_value_attachment"){
				this.condition.values = this.condition_attachment_value;
			}else if (
				["condition_value_input", "condition_value_date", "condition_value_time",
				"condition_value_datetime", "condition_value_number", "condition_value_attachment"].includes(this.current_display) &&
				this.condition_input_value
			) {
				this.condition.values = this.condition_input_value;
			}
			this.addNewCondition()
			this.resetValues()
		},
		hasError(){
			if (this.current_display === 'condition_status' && !this.conditions_status_selection.length){
				this.has_condition_status_error = true
				this.condition_status_error_message = 'Please select a value'
				return true
			}
			if (
				['condition_value_input', 'condition_value_date', 'condition_value_time',
				'condition_value_datetime','condition_value_number'].includes(this.current_display)
				&& !this.condition_input_value
			){
				this.has_input_error = true
				this.input_error_message = 'Please enter a value'
				return true
			}
			if (this.current_display === 'condition_value_multiselect' && !this.condition_value_selection.value){
				this.has_select_error = true
				this.select_error_message = 'Please select a value'
				return true
			}
			return false
		},
		resetErrors(){
			this.has_condition_status_error = this.has_input_error = this.has_select_error = false
			this.condition_status_error_message = this.input_error_message = this.select_error_message = ''
		},
		resetValues(){
			this.condition_fg_selection = {}
			this.condition_value_selection = {}
			this.condition_field_selection = {}
			this.conditions_status_selection = ""
			this.condition_checkbox_value = true
			this.condition_attachment_value = true
			this.condition_input_value = ""
			this.selected_condition = ""
			this.current_display = "condition_type"
		},
		openItems(){
			if (!["condition_type", "condition_value_checkbox", "condition_value_input", "condition_value_date",
			"condition_value_time", "condition_value_datetime", "condition_value_number", "condition_value_attachment"].includes(this.current_display)){
				if (this.current_display == 'condition_status' && this.conditions_status_selection){ return }
				if (this.current_display == 'condition_value_multiselect' && this.condition_value_selection.value){ return }
				this.$refs[this.current_display].showPopup()
			}
		},
	},
}
</script>
<style lang="scss" scoped>
	.date-time-container{
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.date-time{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
</style>